.gallery-page {
  position: relative;
  z-index: 1;
  height: 90%;
  width: 95%;
  overflow-y: scroll;
  color: #ffff26;
  margin: 0 auto;
}

.gallery-page h1 {
  position: relative;
  z-index: 9;
  font-size: xxx-large;
  mix-blend-mode: difference;
  width: 90vw;
  height: fit-content;
  text-align: center;
}

.gallery-page .gallery img {
  max-width: 500px;
  margin-bottom: 1.5rem;
}
