.fashion-page {
  position: relative;
  z-index: 1;
  height: 100%;
  overflow-y: auto;
  padding-left: 5rem;
  padding-right: 5rem;
  margin-top: 3rem;
}

.fashion-page h1 {
  color: #ffff26;
  text-transform: uppercase;
  padding-bottom: 1rem;
  font-size: xxx-large;
}

.fashion-page .intro-paragraph h1 {
  width: 100%;
  font-size: 2.5em;
  border-bottom: 4px solid #f62e97;
}

.fashion-page .intro-section {
  padding-bottom: 10rem;
  height: 100%;
}

.fashion-page .intro-block img {
  width: 100%;
}

.fashion-page .intro-block p {
  color: #fff;
  font-family: "Neue-Medium";
}

.fashion-page .intro-block.left-align {
  margin-left: 10rem;
}

.fashion-page .intro-block.right-align {
  float: right;
  text-align: right;
  margin-right: 10rem;
  margin-top: -10rem;
}

.fashion-page .gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10rem;
}

.fashion-page .gallery img {
  height: 100%;
  max-width: 600px;
  min-width: 300px;
}

.fashion-page .gallery img.left-image {
  position: relative;
  margin-left: -15rem;
  z-index: 1;
  transform: rotate(-5deg);
}

.fashion-page .gallery img.right-image {
  position: relative;
  margin-right: -15rem;
  margin-top: -3rem;
  z-index: 2;
  transform: rotate(5deg);
}

.fashion-page .show-listings {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 90vw;
  gap: 20px;
  margin: 0 auto;
  margin-top: 3rem;
  margin-bottom: 10rem;
  pointer-events: none;
  transition: all 0.5s ease;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s ease;
}

.fashion-page .show-listings.loaded {
  margin-top: 5rem;
  opacity: 1;
  pointer-events: all;
}

.fashion-page .show-listings .show {
  max-width: 300px;
}

.fashion-page .show-listings .show .cover {
  position: relative;
  display: inline-block;
  width: 100%;
}

.fashion-page .show-listings .show .cover img {
  width: 100%;
}

.fashion-page .show-listings .show .cover .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(5px);
}

.fashion-page .show-listings .show .cover .overlay h2 {
  margin: 0;
  margin-left: 1rem;
  margin-top: 1rem;
  text-transform: uppercase;
  color: #fff;
  background-color: #f62e97;
  font-family: "VCR-MONO";
  width: fit-content;
  padding: 0.5rem;
  max-width: 200px;
}

.fashion-page .show-listings .show .cover:hover .overlay {
  opacity: 1;
}

@media only screen and (max-width: 768px) {
  .fashion-page {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .fashion-page .intro-paragraph h1 {
    font-size: 1.4em;
  }

  .fashion-page .show-listings {
    width: auto;
    margin-bottom: 5rem;
  }
}
