.canvas {
    position: fixed !important;
    top: 0;
    left: 0;
    transform: translate(2.5%, 2.5%) !important;
    height: 95% !important;
    width: 95% !important;
    transition: all 1s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background: rgb(174,60,209);
    background: -moz-linear-gradient(167deg, rgba(0, 0, 0,1) 0%, rgba(31,14,105,1) 71%);
    background: -webkit-linear-gradient(167deg, rgba(0,0,0,1) 0%, rgba(31,14,105,1) 71%);
    background: linear-gradient(167deg, rgba(0,0,0,1) 0%, rgba(31,14,105,1) 71%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ae3cd1",endColorstr="#1f0e69",GradientType=1);
    border-radius: 10px;
}

/* .darken {
    mix-blend-mode: darken !important;
    opacity: 0.92;
}

.difference {
    mix-blend-mode: difference !important;
    opacity: 0.94;
}

.hard-light {
    mix-blend-mode: hard-light;
    opacity: 0.96;
} */

/*
darken
difference
hard-lgiht
*/