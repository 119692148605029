.footer {
  position: fixed;
  bottom: 0;
  z-index: 2;
  font-family: "VCR-MONO";
  color: #ffff26;
  mix-blend-mode: difference;
  margin-top: -20px;
}

.footer marquee {
  display: flex;
  flex-direction: row;
  width: 100%;
  cursor: pointer;
}

.footer marquee h1 {
  font-size: 3em;
  word-spacing: -10px;
  font-family: "VCR-MONO";
}

.footer marquee h1 > span {
  margin-right: 5rem;
}

@media only screen and (max-width: 768px) {
  .footer {
    left: 0;
  }

  .footer marquee h1 {
    margin: 0;
  }
}
