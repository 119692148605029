.news-page {
  position: relative;
  z-index: 1;
  height: 100%;
  overflow-y: auto;
  padding-left: 5rem;
  padding-right: 5rem;
  margin-top: 3rem;
}

.news-page .title {
  color: #ffff26;
  text-transform: uppercase;
  padding-bottom: 1rem;
  width: 100%;
  font-size: 2.5em;
  border-bottom: 4px solid #f62e97;
}

.news-page .loading-text {
  color: #ffff26;
  text-align: center;
}

.news-page .news-posts {
  display: flex;
  flex-direction: column;
  width: 90vw;
  gap: 150px;
  margin: 0 auto;
  margin-top: 3rem;
  margin-bottom: 10rem;
  pointer-events: none;
  transition: all 0.5s ease;
  opacity: 0;
  pointer-events: none;
}

.news-page .news-posts.loaded {
  margin-top: 5rem;
  opacity: 1;
  pointer-events: all;
}

.news-page .news-posts .post {
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.news-page .news-posts .post .cover {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 500px;
  max-height: 500px;
  overflow-y: clip;
}

.news-page .news-posts .post .cover img {
  width: 100%;
}

.news-page .news-posts .post .post-copy-container .title {
  margin-top: 0;
  font-family: "VCR-MONO";
}

.news-page .news-posts .post .post-copy-container .date {
  color: #ffff26;
  font-family: VCR-MONO;
  font-size: 0.9em;
}

.news-page .news-posts .post .post-copy-container {
  max-width: 600px;
}

.news-page .news-posts .post .post-copy-container .description {
  font-family: "Neue-Medium";
  color: #fff;
}

.news-page .news-posts .post .post-copy-container .description span {
  display: block;
  margin-bottom: 0.5rem;
}

.news-page .news-posts .post .post-copy-container .description a {
  color: #f62e97;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .news-page {
    padding-left: 1rem;
    padding-right: 1rem;
    overflow-x: hidden;
  }

  .news-page .title {
    font-size: 2em;
  }

  .news-page .news-posts {
    gap: 50px;
  }

  .news-page .news-posts .post {
    flex-direction: column;
  }

  .news-page .news-posts .post .post-copy-container {
    max-width: 300px;
  }

  .news-page .news-posts .post .post-copy-container .description h2 {
    font-size: 1em;
  }
}
