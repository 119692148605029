.projects-page {
  position: relative;
  z-index: 1;
  height: 100%;
  overflow-y: scroll;
}

.projects-page .title {
  width: 90vw;
  text-align: center;
  text-transform: uppercase;
  color: #ffff26;
  font-size: xxx-large;
  margin: 0 auto;
}

.projects-page .loading-text {
  color: #ffff26;
  font-size: xxx-large;
  text-transform: uppercase;
  text-align: center;
  margin-top: 20rem;
}

.projects-page .projects-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 90vw;
  gap: 20px;
  margin: 0 auto;
  justify-content: center;
  margin-top: 3rem;
  margin-bottom: 5rem;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s ease;
}

.projects-page .projects-container.loaded {
  opacity: 1;
  margin-top: 5rem;
  pointer-events: all;
}

.projects-page .projects-container .project {
  max-width: 300px;
}

.projects-page .projects-container .project .cover {
  position: relative;
  display: inline-block;
  width: 100%;
}

.projects-page .projects-container .project .cover img {
  width: 100%;
}

.projects-page .projects-container .project .cover .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(5px);
}

.projects-page .projects-container .project .cover .overlay h2 {
  margin: 0;
  margin-left: 1rem;
  margin-top: 1rem;
  text-transform: uppercase;
  color: #fff;
  background-color: #f62e97;
  font-family: "VCR-MONO";
  width: fit-content;
  padding: 0.5rem;
  max-width: 200px;
}

.projects-page .projects-container .project .cover:hover .overlay {
  opacity: 1;
}

.projects-page .projects-container .dropdown-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  backdrop-filter: blur(40px);
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  margin-top: -2rem;
  pointer-events: none;
  transition: all 0.5s ease;
}

.projects-page .projects-container .dropdown-container.show {
  margin-top: 0;
  opacity: 1;
  pointer-events: all;
}

.projects-page .projects-container .dropdown-content {
  width: 80vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  padding: 2rem;
  border-radius: 10px;
  max-height: 700px;
}

.projects-page .projects-container .dropdown-content h1 {
  color: #ffff26;
  font-size: xxx-large;
  margin: 0;
}

.projects-page .projects-container .dropdown-content h2 {
  color: #fff;
  background-color: #f62e97;
  width: fit-content;
  padding: 0.5rem;
  text-transform: uppercase;
  font-family: "VCR-MONO";
  margin-top: 0;
}

.projects-page .projects-container .dropdown-content .description {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.projects-page .projects-container .dropdown-content .description h3 {
  color: #fff;
  font-family: "Neue-Medium";
  font-weight: lighter;
  max-width: 50%;
}

.projects-page .projects-container .dropdown-content .description ul {
  padding-left: 0;
  color: #fff;
  list-style: none;
  font-family: "VCR-MONO";
}

.projects-page .projects-container .dropdown-content a {
  border: 1px solid #fff;
  color: #fff;
  background-color: #000;
  padding: 1rem 3rem;
  width: fit-content;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "VCR-MONO";
  transition: all 0.25s ease;
  cursor: pointer;
}

.projects-page .projects-container .dropdown-content a:hover {
  color: #000;
  background-color: #fff;
}

@media only screen and (max-width: 768px) {
  .projects-page {
    overflow-x: hidden;
  }

  .projects-page .projects-container {
    width: fit-content;
  }

  .projects-page .loading-text {
    margin-top: 7rem;
    font-size: xx-large;
  }

  .projects-page .projects-container .dropdown-content .description {
    flex-direction: column;
  }

  .projects-page .projects-container .dropdown-content .description h3 {
    max-width: fit-content;
  }

  .projects-page .projects-container .dropdown-content {
    max-height: 400px;
  }

  .projects-page .projects-container .dropdown-container {
    flex-direction: column;
  }

  .projects-page .projects-container .dropdown-container .return-button {
    cursor: pointer;
    width: fit-content;
    color: #fff;
    background-color: #f62e97;
    padding: 1rem;
  }

  .projects-page .projects-container .dropdown-content a {
    margin-bottom: 4rem;
  }
}
