.contact-container {
    position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(20px);
    z-index: 9;
    top: 0;
    left: 0;
    transition: all .5s ease;
    pointer-events: none;
    opacity: 0;
}

.contact-container.show {
    pointer-events: all;
    opacity: 1;
}

.contact-container.hide {
    pointer-events: none;
    opacity: 0;
}

.contact-container .contact-info {
    background-color: rgba(0, 0, 0, 0.6);
    padding: 5rem;
    border-radius: 10px;
}

.contact-container .contact-info h1 {
    color: #fff;
}

.contact-container .contact-info h1 a {
    text-decoration: none;
    color: #fff;
    appearance: none;
}

@media only screen and (max-width: 768px) {
    .contact-container .contact-info {
        padding: 3rem;
    }
}