.services-page {
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.services-page .services-container {
  display: flex;
  width: 90%;
  flex-wrap: nowrap;
  gap: 20px;
  margin: 0 auto;
  align-items: flex-start;
  justify-content: center;
  padding-top: 2rem;
}

.services-page .services-container .service-card {
  max-height: 400px;
  max-width: 400px;
  overflow-y: scroll;
  width: 300px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 1rem;
  border-radius: 10px;
  backdrop-filter: blur(10px);
}

.services-page .services-container .service-card .service-block {
  margin-bottom: 0.5rem;
}

.services-page .services-container .service-card ul {
  padding-left: 1rem;
  margin: 0;
}

.services-page .services-container .service-card .service-title {
  font-family: "VCR-MONO";
  font-size: 1em;
  margin: 0;
  margin-bottom: 0.5rem;
}

.services-page .services-container .service-card .service-block .dropdown-li {
  font-family: "Neue-Medium";
  font-weight: lighter;
}

.services-page .services-container .service-card h1 {
  color: #ffff26;
  text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
  .services-page {
    height: fit-content;
  }

  .services-page .services-container {
    flex-direction: column;
    align-items: center;
  }

  .services-page .services-container .service-card {
    border-radius: 15px;
  }

  .services-page .services-container .service-card h1 {
    margin-left: 1rem;
  }

  .services-page .services-container .service-card ul {
    margin-left: 1rem;
  }
}

@media only screen and (max-width: 1200px) {
  .services-page .services-container {
    flex-wrap: wrap;
  }
}
