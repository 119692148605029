.project-page {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 90%;
  margin: 0 auto;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 50px;
  padding-top: 3rem;
}

.project-page .media-container {
  width: 50vw;
  height: max-content;
  padding-bottom: 5rem;
}

.project-page .media-container img {
  max-width: 50vw;
}

.project-page .project-details {
  position: sticky;
  top: 0;
  overflow-y: scroll;
  max-height: 600px;
  height: 100%;
}

.project-page .project-details .header {
  margin-bottom: 2rem;
}

.project-page .project-details .header h1 {
  color: #ffff26;
  font-size: xxx-large;
  margin-bottom: 0;
  margin-top: 0;
}

.project-page .project-details .header h3 {
  background-color: #f62e97;
  color: #fff;
  font-family: VCR-MONO;
  margin-top: 0;
  padding: 0.5rem;
  text-transform: uppercase;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.project-page .project-details .details {
  margin-bottom: 3rem;
}

.project-page .project-details .details p {
  font-family: "Neue-Medium";
  font-size: 1em;
  color: #fff;
  max-width: 500px;
}

.project-page .project-details .details ul {
  color: #fff;
  font-family: VCR-MONO;
  list-style: none;
  padding-left: 0;
}

.project-page .project-details a {
  font-family: VCR-MONO;
  font-size: 2em;
  text-decoration: none;
  color: #fff;
  background-color: #000;
  padding: 1rem 2rem;
}

@media only screen and (max-width: 768px) {
  .project-page {
    flex-direction: column;
    padding-top: 2rem;
    height: auto; /* Let it grow to accommodate content */
    overflow-x: hidden; /* Allow vertical scrolling */
  }

  .project-page .media-container {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    gap: 30px;
    width: 100%;
    height: auto;
    padding-bottom: 0;
    align-items: center;
  }

  .project-page .media-container img {
    scroll-snap-align: center;
    width: 100%;
    max-width: 500px;
    height: 100%;
  }

  .project-page .project-details {
    position: relative;
    padding-bottom: 5rem;
    max-height: none;
    overflow-y: hidden;
  }

  .project-page .project-details .header h1 {
    margin-top: 0;
    font-size: xx-large;
  }
}
