@font-face {
  font-family: "Neue-Medium";
  src: url(./Assets/NeueHaasDisplayMedium.ttf);
}
@font-face {
  font-family: "Neue-Bold";
  src: url(./Assets/NeueHaasDisplayBold.ttf);
}
@font-face {
  font-family: "Neue-Black";
  src: url(./Assets/NeueHaasDisplayBlack.ttf);
}
@font-face {
  font-family: "VCR-MONO";
  src: url(./Assets/VCR_OSD_MONO.ttf);
}

body {
  height: fit-content;
  width: auto;
  padding: 1rem;
  background-color: #ffff26;
}

*::-webkit-scrollbar {
  width: 0;
}

* {
  appearance: none;
}

main {
  width: 100%;
  height: 90vh;
  overflow: hidden;
}

h1 {
  font-family: "Neue-Bold";
}

@media only screen and (max-width: 768px) {
  main {
    overflow-y: scroll;
  }
}
