.video-reel-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transform: scale(0);
    pointer-events: none;
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
}

.show {
    transform: scale(1);  
    pointer-events: all;
}

.hide {
    transform: scale(0);
}

.video-reel-container iframe {
    width: 70vw;
    height: 65vh;
}