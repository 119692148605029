.individual-fashion-page {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 90vw;
  margin-top: 3rem;
  margin: 0 auto;
  overflow-y: scroll;
  border-radius: 10px;
}

.individual-fashion-page a {
  text-decoration: none;
}

.individual-fashion-page .header-title {
  font-size: 8em;
  margin-bottom: 0;
  margin-top: 0.5rem;
  text-transform: uppercase;
}

.individual-fashion-page .cover-image img {
  width: 100%;
}

.individual-fashion-page .cover-caption h2 {
  font-size: 3em;
  font-family: "Neue-Medium";
  line-height: 100%;
}

.individual-fashion-page .gallery .item {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
}

.individual-fashion-page .gallery .item div {
  width: 45%;
  margin-bottom: 10px;
}

.individual-fashion-page .gallery img,
video {
  width: 100%;
  object-fit: cover;
}

.individual-fashion-page .gallery .item p {
  font-size: 1em;
  font-family: "VCR-MONO";
  position: absolute;
  margin-top: -3.5rem;
  margin-left: 0.5rem;
  padding: 1rem;
  text-transform: uppercase;
  mix-blend-mode: difference;
}

.individual-fashion-page .gallery .item .roles ul {
  list-style: none;
}

.individual-fashion-page .gallery .item .roles ul li {
  font-size: 2em;
  display: flex;
  gap: 15px;
  font-family: "Neue-Medium";
}

.individual-fashion-page .gallery .item .roles ul li span {
  color: #fff;
}

@media only screen and (max-width: 768px) {
  .individual-fashion-page .header-title {
    font-size: 2.5em;
  }

  .individual-fashion-page h2 {
    font-size: 1.4em !important;
  }

  .individual-fashion-page .gallery .item div {
    width: 100%;
  }

  .individual-fashion-page .gallery .item .roles ul {
    padding-left: 0;
  }

  .individual-fashion-page .gallery .item .roles ul li {
    font-size: 1.5em;
  }

  .individual-fashion-page .gallery {
    margin-bottom: 3rem;
  }
}
