.home-page {
  position: relative;
  z-index: 2;
  pointer-events: none;
  font-family: "Neue-Medium";
}

.home-page .quote-container {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  margin-top: -2rem;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.home-page .quote-container.loaded {
  margin-top: 7rem;
  opacity: 1;
}

.home-page .quote-container img {
  width: 700px;
}

.home-page .quote-container h1 {
  text-transform: uppercase;
  position: absolute;
  font-size: 4em;
  max-width: 60vw;
  text-align: justify;
  mix-blend-mode: difference;
  color: #ffff26;
}

@media only screen and (max-width: 768px) {
  .home-page .quote-container {
    margin-top: 5rem;
  }
  .home-page .quote-container h1 {
    font-size: 2em;
    max-width: 90vw;
    text-align: center;
  }

  .home-page .quote-container img {
    width: 60%;
  }
}
