header {
  position: sticky;
  top: 1vh;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  mix-blend-mode: difference;
}

header .logo-container {
  position: relative;
  width: 250px;
  height: 50px;
  padding-left: 2rem;
  overflow: hidden;
}

header .logo-container img {
  max-width: 100%;
  height: auto;
  display: block;
}

header .navigation {
  display: flex;
  width: 300px;
  align-items: center;
  gap: 15px;
  padding-right: 2rem;
}

header .navigation img {
  max-width: 100%;
  mix-blend-mode: difference;
  filter: brightness(0) saturate(100%) invert(1);
}

header .navigation #item-label {
  position: fixed;
  background-color: #fff;
  z-index: 2;
  height: fit-content;
  width: fit-content;
  padding: 0.25rem;
  font-family: "VCR-MONO";
  color: #000;
  opacity: 0;
  pointer-events: none;
}

header .navigation #item-label.show {
  opacity: 1;
  pointer-events: all;
}

@media only screen and (max-width: 768px) {
  header {
    position: relative;
    flex-direction: column;
    gap: 1rem;
    transform: scale(0.9);
  }

  header .logo-container {
    padding-left: 0;
    width: fit-content;
  }

  header .navigation {
    width: fit-content;
    padding-right: 0;
  }

  header.hide {
    display: none;
  }
}
