.about-page {
  position: relative;
  z-index: 1;
  height: 100%;
  overflow-y: auto;
  display: flex;
  color: #fff;
  gap: 50px;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 5rem;
}

.about-page h1 {
  font-size: xxx-large;
  margin-bottom: 0;
}

.about-page p {
  font-family: "Neue-Medium";
  font-size: x-large;
  overflow-y: auto;
  max-height: 500px;
}

.about-page .about-container {
  width: 100%;
  max-width: 600px;
}

.about-page .creative-vision-container {
  width: 100%;
  max-width: 600px;
}

@media only screen and (max-width: 768px) {
  .about-page {
    height: fit-content;
    flex-direction: column;
  }

  .about-page h1 {
    margin-top: 0;
  }

  .about-page p {
    max-height: none;
  }
}
